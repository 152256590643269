/*-----------------------
        FP slider
-----------------------*/

.view-fp-slider {
  margin-bottom: 70px;

  .slide {

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}


/*-----------------------
        Advantages
-----------------------*/

.pane-block-4 {
  margin-bottom: 60px;

  .three-col {
    [class^='col-'] {
      @extend .clearfix;
    }
  }

  .ls {
    width: 90px;
    float: left;
    border-right: 1px solid #dfdfdf;
  }

  .rs {
    padding-left: 115px;
  }

  .advantage-title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
}


/*-----------------------
        Cubes
-----------------------*/

.node-page {
  margin-bottom: 40px;

  & > .content {
    @extend .clearfix;
  }

  .block-cube {
    margin-bottom: 20px;

    a {
      display: flex;
      align-items: flex-end;
      height: 560px;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }

  .bc-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 75px;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 35px;
    text-transform: uppercase;
  }

  .block-cube:first-child {
    width: 100%;

    a {
      height: 630px;
    }
  }

  .block-cube:not(:first-child) {
    width: calc(50% - 10px);
    float: left;
  }

  .block-cube:nth-child(2n + 3) {
    margin-left: 20px;
  }

  .block-cube:last-child:nth-child(even) {
    width: 100%;
    float: none;
    clear: both;
  }

  .block-cube:nth-child(3n + 1) {
    .bc-title {
      background: rgba(12, 105, 82, 0.8);
    }
  }

  .block-cube:nth-child(3n + 2) {
    .bc-title {
      background: rgba(220, 218, 6, 0.8);
    }
  }

  .block-cube:nth-child(3n + 3) {
    .bc-title {
      background: rgba(222, 27, 27, 0.8);
    }
  }

  .block-cube:nth-child(3n + 4) {
    .bc-title {
      background: rgba(245, 156, 17, 0.8);
    }
  }

  .block-cube:nth-child(3n + 5) {
    .bc-title {
      background: rgba(226, 0, 117, 0.8);
    }
  }
}
