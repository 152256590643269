/*-----------------------
        Media
-----------------------*/

@media all and (max-width: 1199px) {
  .search input[name="key"] {
    width: 220px;
  }

  .node-page {
    .block-cube:first-child a {
      height: 520px;
    }

    .block-cube a {
      height: 460px;
    }
  }

  .not-front .node-page .block-cube a {
    height: 550px;
  }

  .toy-teaser {
    .price-iw {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .add-cart-message-wrapper {
    left: 15%;
    right: 15%;
  }

  .view-catalog .category a {
    height: 460px;
  }
}


@media all and (max-width: 991px) {
  .main-menu .menu.nav li:nth-child(4) {
    &:before {
      content: none;
    }

    a {
      padding-left: 0;
    }
  }

  .pane-block-4 {
    .three-col {
      .col-md-4 + .col-md-4 {
        margin-top: 20px;
      }
    }
  }

  .node-page {
    .block-cube:first-child a {
      height: 400px;
    }

    .block-cube a {
      height: 350px;
    }

    .bc-title {
      min-height: 65px;
      font-size: 26px;
      line-height: 30px;
    }
  }

  .not-front .node-page .block-cube a {
    height: 425px;
  }

  .footer-iw {
    .f-top {
      .footer-col {
        flex-basis: 50%;
        margin-bottom: 20px;
      }
    }

    .f-bottom {
      margin-top: 0;
    }
  }

  .contacts_time,
  .contacts_legal-address {
    text-align: left;
  }

  .node-type-page {
    .punkt-image {
      width: 400px;
    }
  }

  .page-delivery-and-payment .field-name-body {
    background: none;
  }

  .views-field-field-place-department {
    .field-content {
      display: block;
    }

    .department + .department {
      margin-top: 20px;
    }
  }

  .aside {
    margin-bottom: 50px;
  }

  .add-cart-message-wrapper {
    left: 10%;
    right: 10%;
  }

  .node-product {
    .main-part {
      margin-top: 25px;
    }
  }

  .view-catalog .category a {
    height: 350px;
  }
}


@media all and (max-width: 767px) {

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
  }

  header {
    padding: 15px 0 20px;
  }

  .header-top {
    display: block;
  }

  .header-bottom {
    position: relative;
  }

  .ht-rs {
    margin-top: 15px;

    .sub-menu {
      flex-wrap: wrap;
      width: calc(100% - 50px);
      margin-top: 20px;

      & > div {
        flex-basis: 50%;
        text-align: left;
      }

      & > div:not(:first-child):before {
        content: none;
      }

      & > div:nth-child(3) {
        padding-left: 0;
      }
    }

    .ajax-shopping-cart-teaser {
      top: 0;
      right: auto;
      left: 60px;

      .cart-quantity:before {
        content: none;
      }
    }
  }

  .logo-wrapper {
    text-align: center;
  }

  .site-slogan {
    padding-right: 0;
    text-align: center;
  }

  .main-menu {
    .navbar-toggle {
      position: absolute;
      top: -45px;
      right: 0;
      margin: 0;
      border-radius: 4px;
      border: 1px solid #00a27b;

      .icon-bar {
        background: #00a27b;
      }
    }

    .menu.nav {
      margin: 8px 0;

      li a {
        padding: 0;
      }

      li + li {
        margin-top: 5px;

        &:before {
          content: none;
        }
      }
    }
  }

  #edit-search-wrapper {
    width: 100%;
  }

  .search input[name="key"] {
    width: 100%;
  }

  .pane-block-4 {
    .ls {
      width: 75px;
    }

    .rs {
      padding-left: 90px;
    }
  }

  .node-page .block-cube:first-child {
    a {
      height: 350px;
    }
  }

  .node-page {
    .block-cube:first-child a {
      height: calc((100vw - 30px) * 400 / 720);
    }

    .block-cube a {
      height: calc((50vw - 30px) * 355 / 350);
    }

    .block-cube:last-child:nth-child(even) a {
      height: calc((100vw - 30px) * 355 / 720);
    }

    .bc-title {
      min-height: 40px;
      font-size: 25px;
    }
  }

  .not-front .node-page {
    .block-cube a {
      height: calc((50vw - 30px) * 425 / 350);
    }

    .block-cube:first-child a,
    .block-cube:last-child:nth-child(even) a {
      height: calc((100vw - 30px) * 425 / 720);
    }
  }

  .footer-iw {
    margin: 0 -15px 0 -15px;
    padding: 25px 15px;

    .f-top {
      .footer-col {
        flex-basis: 100%;
      }
    }
  }

  .payment-methods ul {
    flex-wrap: wrap;

    li {
      flex-basis: 33.333%;
      margin-top: 10px;
      text-align: center;
    }

    li + li {
      margin-left: 0;
    }
  }

  .node-type-page .philosophy {
    display: block;

    .philosophy-image {
      max-width: 350px;
      width: auto;
      text-align: left;
    }

    .philosophy-text {
      padding-left: 0;
    }
  }

  .node-type-page .punkt {
    display: block;

    .punkt-image {
      max-width: 350px;
      width: auto;
      margin: 0 auto;
    }

    .punkt-text {
      padding-left: 0;
    }
  }

  .page-wholesale {
    .pane-webform-client-block-20 {
      width: 100%;
      float: none;
    }

    .pane-node {
      width: 100%;
      float: none;
      padding-left: 0;
    }
  }

  .view-latest-blogs {
    .blog-image {
      float: none;
      margin-right: 0;
    }

    .rs {
      margin-top: 15px;
      padding-left: 0;
    }
  }

  .department {
    .dep-phones {
      display: block;
    }

    .phone + .phone:before {
      content: none;
    }
  }

  .webform-client-form-16 {
    .webform-component--name,
    .webform-component--email,
    .webform-component--phone {
      width: 100%;
      float: none;
    }

    .webform-component--email,
    .webform-component--phone {
      margin-left: 0;
    }
  }

  #map {
    height: 350px;
  }

  .node-video {
    .content {
      display: block;
    }

    .cat-video-descr {
      margin-top: 10px;
      padding-left: 0;
    }
  }

  .videos {
    .video {
      a {
        display: block;
      }
    }

    .video-number {
      display: inline-block;
      float: left;
    }

    .video-image {
      display: inline-block;
    }

    .ls {
      clear: both;
      margin-top: 10px;
      padding-left: 0;
    }
  }


  /*======Product=======*/

  .node-product {
    .price {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
    }

    .old-price {
      margin-right: 0;
      margin-bottom: 15px;
    }

    .commerce-add-to-cart > div {
      display: block;
    }

    .field-name-field-product .form-submit {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  .product-tabs {
    .nav-tabs {
      border-bottom: none;
    }

    li {
      float: none;
      border-bottom: none !important;
    }

    li + li {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  .add-cart-message-wrapper {
    left: 5%;
    right: 5%;

    .option-button-wrapper {
      float: none;
      margin-top: 20px;
    }
  }

  #comments .dropup {
    padding-left: 0;
    text-align: center;
  }

  .comment {
    .c-author-letter {
      width: 75px;
      height: 75px;
      font-size: 50px;
      line-height: 75px;
    }

    .comment-content {
      padding-left: 90px;
    }
  }

  .field-name-field-similar-products {
    padding: 0;

    .slick-button-prev {
      left: 0;
    }

    .slick-button-next {
      right: 0;
    }
  }
}


@media all and (max-width: 639px) {
  .node-page {
    .block-cube {
      width: 100% !important;
      float: none;
      margin-left: 0 !important;
    }
  }
}


@media all and (max-width: 599px) {
  .product-slider {
    width: 100%;
    float: none;
  }

  .product-slider-nav {
    width: 100%;
    float: none;
    padding: 0 20px;

    .slick-slide {
      display: flex;
      justify-content: center;
    }

    .slick-arrow {
      top: 50%;
      bottom: auto;
    }

    .slick-button-prev {
      left: -12px;
      right: auto;
      transform: translateY(-50%) rotate(-90deg);
    }

    .slick-button-next {
      left: auto;
      right: -12px;
      transform: translateY(-50%) rotate(90deg);
    }
  }

  .video-cat {
    a {
      flex-direction: column;
    }

    .ls {
      margin-top: 10px;
      padding-left: 0;
    }
  }
}


@media all and (max-width: 480px) {
  .node-page {
    .block-cube a {
      height: calc((100vw - 30px) * 355 / 350);
    }
  }

  .not-front .node-page {
    .block-cube a {
      height: calc((100vw - 30px) * 425 / 350);
    }
  }

  .view-catalog {
    .category:nth-child(4n + 1),
    .category:nth-child(4n + 4) {
      a {
        height: calc((100vw - 30px) * 560 / 652);
      }
    }

    .category:nth-child(4n + 2),
    .category:nth-child(4n + 3) {
      a {
        height: calc((100vw - 30px) * 560 / 458);
      }
    }

    .category:last-child:nth-child(odd) {
      a {
        height: calc((100vw - 30px) * 560 / 1140);
      }
    }
  }

  .toy-teaser {
    .price {
      padding-left: 6px;
    }

    .commerce-add-to-cart {
      .form-submit {
        padding: 6px 6px;
      }
    }
  }

  .params {
    flex-direction: column;
    margin-bottom: 10px;
  }
}
