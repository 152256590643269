/*-----------------------
        Video
-----------------------*/

.node-video {
  margin-top: 30px;

  .content {
    display: flex;
  }

  .field-name-field-cat-video-image {
    flex-shrink: 0;
  }

  .cat-video-descr {
    padding-left: 30px;
    font-size: 18px;
    line-height: 20px;
  }
}

.modal {
  @media all and (min-width: 768px) {
    .modal-dialog {
      top: 50%;
      transform: translateY(-50%) !important;
    }
  }

  .modal-dialog {
    position: relative;

    @media (min-width: 768px) {
      width: 420px;
    }
  }

  .modal-content {
    border-radius: 0;
  }

  .close {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 36px;
    height: 35px;
    background: url("../images/close.png");
    outline: none;
    opacity: 1;
    text-indent: -9999px;
    z-index: 1051;
  }

  .modal-body {
    padding: 0;
    line-height: 0;
  }

  .field-label {
    display: none;
  }
}

.videos {
  margin: 40px 0 50px;

  .video {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;

    a {
      display: flex;
      align-items: flex-start;
      color: #000;
      text-align: left;

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .video:last-child {
    border-bottom: none;
  }

  .video-number {
    flex-shrink: 0;
    width: 50px;
    height: 65px;
    line-height: 65px;
    text-align: center;
  }

  .video-img {
    flex-shrink: 0;
  }

  .ls {
    padding-left: 30px;
    white-space: normal;
  }

  .video-title {
    font-weight: 600;
    font-size: 17px;
  }

  .video-descr {
    font-size: 16px;
    line-height: 18px;
  }
}


/*-----------------------
        Video reviews
-----------------------*/

.view-video {
  margin-top: 30px;
  margin-bottom: 50px;

  .views-row {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd;
  }

  .views-row:last-child {
    border-bottom: 0;
  }
}

.video-cat {
  user-select: none;

  a {
    display: flex;
    align-items: flex-start;
    color: #000;
  }

  .img-wr {
    flex-shrink: 0;
    position: relative;
    border: 1px solid #ddd;
  }

  .video-n {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100px;
    background: rgba(0,0,0,0.6);
    color: #fff;
    font-size: 18px;
    font-weight: 600;

    span {
      display: block;
    }
  }

  .ls {
    padding-left: 30px;
  }

  .cat-title {
    font-weight: 600;
    font-size: 17px;
  }

  .cat-descr {
    font-size: 16px;
    line-height: 18px;
  }
}











