/*-----------------------
        Header
-----------------------*/

header {
  padding: 30px 0 20px;
}

.header-top {
  display: flex;
  justify-content: space-between;
}

.ht-rs {
  text-align: right;

  .phones {
    ul {
      margin-bottom: 0;
    }

    a {
      color: #bdbdbd;
      font-size: 16px;
      line-height: 16px;
    }
  }

  .sub-menu {
    display: flex;
    align-items: center;
    margin-top: 45px;

    & > div {
      position: relative;
      padding: 0 12px;
    }

    & > div:first-child {
      padding-left: 0;
    }

    & > div:last-child {
      padding-right: 0;
    }

    & > div:not(:first-child) {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 11px;
        background: #959595;
      }
    }
  }

  .currency {
    display: flex;
    align-items: center;

    .form-type-select {
      margin-bottom: 0;
    }

    .bootstrap-select.form-select {
      display: block !important;
      height: auto;

      button {
        padding: 5px 5px 5px 7px;
        margin: 0;
        border-radius: 0;
        border: none;
        background: transparent;
        color: #959595;
        font-size: 17px;
        font-weight: 600;
        line-height: 17px;
        outline: none !important;
        box-shadow: none;
      }

      &.open {
        button {
          border: none;
        }
      }

      .bs-caret {
        .caret {
          top: 8px;
          right: 0;
          margin-top: 0;
          border-style: solid;
          border-width: 6px 4px 0 4px;
          border-color:  #00ac83 transparent transparent transparent;
        }
      }

      .dropdown-menu {
        border-radius: 0;
        box-shadow: none;
      }
    }

    .dropdown-menu {
      top: 25px;
    }
  }

  .currency-text,
  .account a,
  .cart a {
    color: #959595;
    font-weight: 600;
    text-transform: lowercase;
  }

  .cart {
    position: relative;
  }

  .ajax-shopping-cart-teaser {
    position: absolute;
    top: -24px;
    right: 14px;

    .cart-quantity {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 2px;
      background: #2cc094;
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;

      &:before {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color:  #2cc094 transparent transparent transparent;
      }
    }
  }
}

#commerce-multicurrency-selector-form {
  outline: none;
}

.language-switcher-locale-url {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
  }

  &,
  a {
    font-size: 16px;
    font-weight: 600;
    color: #737373;

    &.active {
      color: #36c69d;
    }
  }
}

.site-slogan {
  margin-top: 10px;
  padding-right: 20px;
  color: #cdcc00;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  text-transform: uppercase;
}

.header-bottom {
  margin-top: 5px;

  @media all and (min-width: 768px) {
    .navbar-collapse {
      display: flex !important;
      justify-content: space-between;
      align-items: flex-end;

      &:before,
      &:after {
        content: none;
      }
    }
  }
}

.search {
  .views-exposed-widgets {
    margin-bottom: 0;
  }

  .views-exposed-widget {
    padding: 0;
  }

  .form-item-key {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 25px;
      height: 24px;
      background: url('../images/sprite.png') -35px 0 no-repeat;
    }
  }

  input[name="key"] {
    width: 320px;
    padding: 6px 12px 6px 36px;
    border: 1px solid #e3e4e5;
    border-radius: 0;
    box-shadow: none;
    color: #000;
    font-size: 18px;
    line-height: 18px;

    &:focus {
      box-shadow: none;
      border: 1px solid #959595;
    }
  }

  .views-submit-button {
    display: none;
  }
}


/*-----------------------
        Menu
-----------------------*/

.main-menu {
  .navbar-collapse {
    padding: 0;
  }

  .menu.nav {
    li {
      position: relative;
    }

    li:first-child a {
      padding-left: 0;
    }

    li:last-child a {
      padding-right: 0;
    }

    a {
      padding: 0 10px;
      color: #000;
      font-size: 20px;
      line-height: 20px;
      text-transform: lowercase;

      &:hover,
      &:focus {
        background: none;
      }
    }

    li + li {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 1px;
        width: 1px;
        height: 11px;
        background: #959595;
      }
    }
  }
}












/*-----------------------
        Footer
-----------------------*/

footer {
  min-height: 165px;
}

.footer-iw {
  padding: 25px;
  background: #cdcc00;

  .f-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .f-bottom {
    margin-top: -28px;
  }
}

.footer-menu {
  & > .menu.nav {
    margin-bottom: 0;

    a {
      display: block;
      padding: 0;
      color: #fff;
      font-size: 18px;
      line-height: 18px;

      &:hover,
      &:focus {
        background: transparent;
      }
    }
  }
}

.sister-company {
  h3 {
    margin-top: 5px;
    font-size: 18px;
    color: #FFF;
  }
}
.sister-company--logos {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px 0px;
  @include breakpoint(md) {
    align-items: center;
  }

  a {
    display: block;
    margin-bottom: 8px;
    margin: 5px;
    padding: 0px;
    background: #FFF;
    text-align: center;
    @include breakpoint(md) {
      max-width: 65%;
    }
  }
  img {
    max-width: 100%;
    max-height: 60px;
  }
}

.contacts_footer {
  li.email {
    margin-bottom: 8px;

    a {
      color: #fff;
      font-size: 26px;
      line-height: 26px;
    }
  }

  li:nth-child(n + 2) {
    a {
      color: #fff;
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.contacts_time {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  text-align: right;
}

.contacts_legal-address {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  margin-top: 10px;
}

.payment-methods {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-wrap: wrap;
    max-width: 50%;

    li + li {
      margin-left: 5px;
    }
  }
}

.socials {
  margin-top: 17px;

  .social-list {
    @extend .list-unstyled;
    margin-bottom: 0;

    display: flex;
    align-items: center;

    li + li {
      margin-left: 20px;
    }
  }

  .fa {
    font-size: 18px;
    color: #fff;
  }
}
