/*-----------------------
        Category
-----------------------*/

.view-catalog {
  .view-content {
    @extend .row;
  }

  .category {
    margin-bottom: 30px;

    a {
      display: flex;
      align-items: flex-end;
      height: 560px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .c-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 50px;
      color: #fff;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .category:nth-child(4n + 1),
  .category:nth-child(4n + 4) {
    @extend .col-sm-7;
  }

  .category:nth-child(4n + 2),
  .category:nth-child(4n + 3) {
    @extend .col-sm-5;
  }

  .category:nth-child(5n + 1) {
    .c-title {
      background: rgba(0, 162, 123, 0.8);
    }
  }

  .category:nth-child(5n + 2) {
    .c-title {
      background: rgba(248, 171, 51, 0.8);
    }
  }

  .category:nth-child(5n + 3) {
    .c-title {
      background: rgba(200, 198, 7, 0.8);
    }
  }

  .category:nth-child(5n + 4) {
    .c-title {
      background: rgba(226, 0, 117, 0.8);
    }
  }

  .category:nth-child(5n + 5) {
    .c-title {
      background: rgba(228, 49, 49, 0.8);
    }
  }

  .category:last-child:not(:nth-child(2n)) {
    width: 100%;
  }

  .more-link {
    margin: 10px 0 30px;
    text-align: center;

    a {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
      color: #cdcc00;

      &:hover,
      &:focus {
        background: #cdcc00;
        color: #fff;
      }
    }
  }
}

.pane-term-description {
  margin-bottom: 50px;
  font-size: 18px;
}


/*-----------------------
        Search
-----------------------*/

.view-search-page {
  .view-empty {
    font-size: 18px;
  }
}


/*-----------------------
        Contacts
-----------------------*/

.page-contacts {
  .pane-wx-site-contacts {
    margin: 50px 0 50px;
  }
}

.view-places {
  margin: 10px 0 50px;
}

.views-field-field-place-department {
  margin-bottom: 30px;

  .field-content {
    display: flex;
  }
}

.department {
  .dep-title {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  a {
    color: #000;
  }

  .dep-phones {
    display: flex;
    position: relative;
    margin-top: 15px;
    padding-left: 40px;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 30px;
      height: 24px;
      background: url('../images/sprite.png') -65px 0 no-repeat;
    }
  }

  .phone {
    position: relative;
    line-height: 30px;

    .code {
      font-size: 18px;
      font-weight: 300;
    }

    .number {
      font-size: 22px;
    }
  }

  .phone + .phone {
    &:before {
      content: '/';
      display: inline-block;
      margin: 0 6px;
      font-size: 18px;
    }
  }

  .dep-email {
    position: relative;
    margin-top: 8px;
    padding-left: 40px;
    font-size: 15px;
    text-transform: uppercase;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 3px;
      width: 25px;
      height: 16px;
      background: url('../images/sprite.png') -100px 0 no-repeat;
    }
  }
}

@media all and (min-width: 992px) {
  .department + .department {
    margin-left: 50px;
  }
}

#map {
  height: 430px;
}


/*-----------------------
        Opt
-----------------------*/

.page-wholesale {
  .content-center {
    margin-bottom: 50px;
  }

  .pane-block-5 {
    color: #666;

    h1 {
      color: #000;
    }

    ul {
      padding-left: 20px;
    }
  }

  .pane-webform-client-block-20 {
    //width: calc(100% * 10 / 12 * 5 / 12);
    width: calc(100% * 5 / 12);
    float: left;
  }

  .pane-node {
    //width: calc(100% * 10 / 12 * 7 / 12);
    width: calc(100% * 7 / 12);
    padding-left: 30px;
    padding-bottom: 85px;
    background: url('../images/train-logo.png') 50% 100% no-repeat;
    float: left;
    clear: right;
  }

  .files {
    .file {
      position: relative;
      display: inline-block;
      margin: 5px 15px 20px 0;
      padding-left: 35px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 1px;
        width: 29px;
        height: 27px;
        background: url('../images/sprite.png') -130px 0 no-repeat;
      }
    }

    a {
      display: block;
      color: #a57eb4;
      font-size: 14px;
      line-height: 27px;
    }
  }
}


/*-----------------------
        D&P
-----------------------*/

.page-delivery-and-payment {
  .field-name-body {
    color: #666;
    background: url('../images/malchik-s-mashinka.png') 100% 20px no-repeat;
  }

  .content-center {
    margin-bottom: 50px;
  }
}


/*-----------------------
        Achievements
-----------------------*/

.node-type-page {
  .punkt {
    display: flex;
    align-items: center;
  }

  .punkt-image {
    flex-shrink: 0;
    width: 600px;
    text-align: center;
  }

  .punkt-text {
    padding-left: 30px;
    font-size: 18px;
  }

  .punkt + .punkt {
    margin-top: 30px;
  }
}


/*-----------------------
        Philosophy
-----------------------*/

.node-type-page {
  .philosophy {
    display: flex;
    align-items: center;
  }

  .philosophy-image {
    flex-shrink: 0;
    width: 350px;
    text-align: center;
  }

  .philosophy-text {
    padding-left: 30px;
    font-size: 18px;
  }

  .philosophy + .philosophy {
    margin-top: 15px;
  }
}


/*-----------------------
        Glad to see
-----------------------*/

.not-front {
  .node-page {
    .block-cube a {
      height: 670px;
    }
  }
}


/*-----------------------
        Lost part
-----------------------*/

.page-lost-part {
  .pane-block-6 {
    color: #666;

    h1 {
      color: #000;
    }
  }

  .content-center {
    margin-bottom: 50px;
  }

  .webform-component-file {
    .form-submit {
      display: none;
    }
  }
}


/*-----------------------
        Webform
-----------------------*/

.webform-client-form {
  .webform-component--name,
  .webform-component--email,
  .webform-component--phone {

    input {
      border: none;
      border-radius: 0;
      padding: 5px 15px;
      background: #e5e5e5;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .grippie {
    display: none;
  }

  textarea {
    resize: none;
    max-height: 120px;
    border: none;
    border-radius: 0;
    padding: 5px 15px;
    background: #e5e5e5;

    &:focus {
      box-shadow: none;
    }
  }

  .form-actions {
    input {
      @extend .woody-button;
    }
  }
}

.webform-client-form-16 {
  .webform-component--name,
  .webform-component--email,
  .webform-component--phone {
    width: 32%;
    float: left;
  }

  .webform-component--email,
  .webform-component--phone {
    margin-left: 2%;
  }

  .webform-component--message {
    clear: both;
  }
}
