/*-----------------------
        Aside
-----------------------*/

.aside {
  margin-bottom: 50px;

  .menu.nav {
    li + li {
      margin-top: 14px;
    }

    a {
      padding: 0;
      color: #191919;
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;

      &.active,
      &:hover,
      &:focus {
        background: transparent;
        color: #e73b3a;
      }
    }
  }
}
