// Page Layout
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'DINPro';
  src: url('../fonts/DINPro-Regular.eot');
  src: url('../fonts/DINPro-Regular.woff2') format('woff2'),
  url('../fonts/DINPro-Regular.woff') format('woff'),
  url('../fonts/DINPro-Regular.ttf') format('truetype'),
  url('../fonts/DINPro-Regular.svg#DINPro') format('svg'),
  url('../fonts/DINPro-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('../fonts/DINPro-Light.eot');
  src: url('../fonts/DINPro-Light.woff2') format('woff2'),
  url('../fonts/DINPro-Light.woff') format('woff'),
  url('../fonts/DINPro-Light.ttf') format('truetype'),
  url('../fonts/DINPro-Light.svg#DINPro') format('svg'),
  url('../fonts/DINPro-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('../fonts/DINPro-Bold.eot');
  src: url('../fonts/DINPro-Bold.woff2') format('woff2'),
  url('../fonts/DINPro-Bold.woff') format('woff'),
  url('../fonts/DINPro-Bold.ttf') format('truetype'),
  url('../fonts/DINPro-Bold.svg#DINPro') format('svg');
  font-weight: 700;
  font-style: normal;
}


body {
  max-width: 2000px;
  margin: 0 auto;
  color: #000;
  font-family: 'DINPro', sans-serif;
  -webkit-font-smoothing: antialiased;
}

#main-wrapper {
  min-height: calc(100vh - 369px);
}

h1,
h2,
h3 {
  font-family: 'DINPro', sans-serif;
}

.heading {
  h1 {
    margin: 0 0 25px;
  }
}

p:not(:last-child) {
  margin-bottom: 15px;
}

p:last-child {
  margin-bottom: 0;
}

a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.woody-button {
  position: relative;
  display: inline-block;
  padding: 2px 40px 3px 40px;
  background: #cdcc00;
  border: none;
  border-radius: 0;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #fff;
    outline: none;
  }
}

.ajax-progress-throbber {
  display: none;
}

.nav-tabs {
  li a {
    border-radius: 0;
    color: #096546;
  }
}

.slick-slide {
  outline: none;
}

ul.slick-dots {
  display: inline-block;
  position: absolute;
  bottom: -22px;
  right: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 0;
  text-align: center;

  li {
    display: inline-block;

    button {
      width: 10px;
      height: 10px;
      padding: 0;
      background: #bdbec0;
      border: none;
      border-radius: 50%;
      outline: none;
      text-indent: -9999px;
    }

    & + li {
      margin-left: 10px;
    }

    &.slick-active {
      button {
        background: #f7b53d;
      }
    }
  }
}

.node-type-page {
  .content-center {
    margin-bottom: 50px;
  }
}


/*-----------------------
        Breadcrumbs
-----------------------*/

.pane-page-breadcrumb {
  @extend .hidden-xs;
}

.breadcrumb {
  margin-bottom: 26px;
  padding: 0;
  background: transparent;

  .inline {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-transform: uppercase;
  }

  .inline,
  .inline a,
  .delimiter {
    color: #acacac;
  }

  .last,
  .inline:hover a {
    color: #000;
  }
}


/*-----------------------
      Scrollbar
-----------------------*/

@media all and (min-width: 1025px) {
  ::-webkit-scrollbar-button {
    background-image: url("");
    background-repeat: no-repeat;
    width: 6px;
    height: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fcf9f3;
    box-shadow: 0px 0px 3px #69f0bc inset;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: linear-gradient(to bottom, #e43132, #f8ab33, #e20275, #c8c608, #00a27b);
    //box-shadow: 0px 1px 1px #67b492 inset;
    //background-image: url(../images/scroll-img.png);
    background-position: center;
    background-repeat: no-repeat;
  }

  ::-webkit-resizer {
    background-image: url("");
    background-repeat: no-repeat;
    width: 9px;
    height: 0px;
  }

  ::-webkit-scrollbar {
    width: 9px;
  }
}
