/*-----------------------
        Cart + Order
-----------------------*/

.page-cart {
  .page-header {
    margin-top: 50px;
    border-bottom: none;
  }

  #main-wrapper {
    min-height: calc(100vh - 369px);
  }

  .region-content {
    padding-bottom: 85px;
  }

  .views-table {
    th {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    .views-field {
      vertical-align: middle !important;
    }

    .views-field-field-product-image {
      text-align: center;
    }

    .views-field-line-item-title,
    .views-field-title {
      a {
        color: #7f6f5a;
        font-size: 18px;
      }
    }

    .views-field-commerce-unit-price,
    .views-field-commerce-total {
      font-size: 18px;

      .value {
        font-weight: 500;
      }

      .code {
        font-weight: 300;
      }
    }

    .views-field-edit-quantity {

      [class*='form-item-edit-quantity'] {
        display: flex;
        justify-content: center;
      }

      input {
        height: 40px;
        width: 45px;
        padding: 0;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .views-field-edit-delete {
      text-align: center;

      input {
        @extend .woody-button;
        border-bottom: none !important;
        padding: 6px 15px 4px !important;
      }
    }
  }

  .views-field-edit-quantity {
    .commerce-quantity-plusminus-link {
      display: flex;
      align-items: center;

      a {
        margin-bottom: 0;
      }
    }
  }

  .line-item-summary {
    margin-bottom: 25px;
    padding-top: 10px;
    font-size: 18px;

    .line-item-total-label {
      font-weight: 500;
    }

    .value {
      font-weight: 500;
    }

    .code {
      font-weight: 300;
    }
  }

  .form-actions {
    #edit-submit {
      @extend .woody-button;
    }

    #edit-checkout {
      @extend .woody-button;
      margin-right: 0;
      color: #cdcc00 !important;


      display: block;
      background: #fff !important;
      border: 1px solid #e5e5e5 !important;

      &:hover {
        background: #fff;
        border-color: #cdcc00 !important;
      }
    }
  }
}


.page-checkout {
  .page-header {
    margin-top: 50px;
    border-bottom: none;
  }

  #main-wrapper {
    min-height: calc(100vh - 257px);
  }

  label {
    font-size: 14px;
  }

  .view-commerce-cart-summary {

    th {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    td {
      font-size: 17px;
    }

    .component-type-commerce-price-formatted-amount {

      td {
        font-weight: 500;
      }
    }
  }

  .form-item-account-login-mail,
  .customer_profile_billing {
    label {
      font-weight: 500;
    }

    input,
    textarea {
      border-radius: 0;
      max-width: 400px;
      font-size: 17px;
      background: #f3f3f3;

      &:focus {
        border-color: #eccc75;
        box-shadow: none;
        outline: none;
      }

      &::-webkit-input-placeholder {
        font-size: 15px;
      }

      &::-moz-placeholder {
        font-size: 15px;
      }

      &:-moz-placeholder {
        font-size: 15px;
      }

      &:-ms-input-placeholder {
        font-size: 15px;
      }
    }

    textarea {
      resize: none;
    }

    .grippie {
      display: none;
    }
  }

  .commerce_shipping,
  .commerce_payment {
    font-size: 17px;

    input {
      margin: 2px 4px 0 -23px;
    }
  }

  .checkout-buttons {
    .checkout-continue {
      @extend .woody-button;
      margin-right: 10px;
    }

    .checkout-cancel,
    .checkout-back {
      font-size: 15px;
      color: #7f6f5a;
      outline: none !important;
    }
  }


  .checkout-help {
    font-size: 17px;
    margin-bottom: 10px;
  }

  #commerce-checkout-form-review {
    tbody {
      font-size: 17px;
    }

    label,
    .pane-title td,
    .field-label {
      font-weight: 500;
    }
  }

  .checkout-completion-message {
    font-size: 18px;

    a {
      color: #eccc75;
    }
  }

  .form-actions {
    input[type="submit"] {
      @extend .woody-button;
    }
  }
}

.commerce_coupon label {
  display: none;
}

@media all and (min-width: 768px) {
  .page-checkout {
    .group-datetime {
      .panel-body {
        display: flex;
      }

      .field-name-field-delivery-time {
        margin-left: 20px;
      }
    }
  }
}