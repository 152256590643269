/*-----------------------
        Product
-----------------------*/

.node-product {

  h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
  }

  .articul {
    margin-top: 12px;
    font-size: 18px;
    font-weight: 300;
  }

  .field-name-field-product-vote {
    margin-top: 22px;
  }

  .fivestar-static-item {
    .form-group {
      display: flex;
      align-items: center;
      margin: 0;
    }

    .fivestar-default {
      height: 13px;
    }

    .help-block {
      margin: 0 0 0 15px;
    }
  }

  .fivestar-widget-static {
    .star {
      width: 14px;
      height: 13px;
      background: none;

      span.off {
        background: url('../images/star-off.png') 0 50% no-repeat;
      }

      span.on {
        background: url('../images/star-on.png') 0 50% no-repeat;
      }
    }

    .star + .star {
      margin-left: 5px;
    }
  }

  .fivestar-summary {
    &,
    a {
      color: #cdcc00;
      font-size: 17px;
      font-weight: 300;
    }

    a {
      text-decoration: underline;
    }
  }

  .chars {
    margin-top: 42px;

    .bold {
      margin-bottom: 5px;
      font-size: 25px;
      font-weight: 700;
    }

    .container-inline {
      position: relative;
      padding-left: 11px;
      font-size: 17px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 55%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        background: url("../images/sprite.png") 0 0 no-repeat;
      }
    }

    .double-field-first {
      color: #7e7e7e;
      font-weight: 700;
    }

    .double-field-second {
      font-weight: 300;
    }
  }

  .price {
    display: flex;
    align-items: flex-end;
    margin-top: 50px;

    .value {
      font-weight: 700;
    }

    .code {
      font-weight: 300;
    }
  }

  .current-price {
    color: #e9078f;
    font-size: 36px;
    line-height: 34px;
  }

  .old-price {
    position: relative;
    margin-right: 38px;
    color: #404040;
    font-size: 26px;
    line-height: 26px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -5%;
      width: 110%;
      height: 1px;
      background: #676767;
    }
  }

  .field-name-field-product {
    margin-top: 40px;

    label {
      display: none;
    }

    .form-group {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }


    .form-submit {
      @extend .woody-button;
      margin-left: 30px;
    }
  }

  .commerce-add-to-cart {
    & > div {
      display: flex;
    }
  }

  .commerce-quantity-plusminus-link {
    height: 21px;

    a {
      display: block;
      position: relative;
      margin-bottom: 0;
      width: 21px;
      height: 21px;
      padding: 0;
      border-radius: 0;
      text-indent: -9999px;

      &:hover,
      &:focus {
        background: transparent;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 21px;
      }
    }
  }

  .commerce-quantity-plusminus-link-decrease {
    a {
      &:before {
        height: 2px;
        background: url("../images/sprite.png") -10px -25px no-repeat;
      }
    }
  }

  .commerce-quantity-plusminus-link-increase {
    a {
      &:before {
        height: 21px;
        background: url("../images/sprite.png") -10px 0 no-repeat;
      }
    }
  }
}

.form-item-quantity.form-type-hidden {
  .commerce-quantity-plusminus-link {
    display: none;
  }
}

.form-item-quantity.form-type-hidden + .form-submit {
  margin-left: 0;
}

.product-slider {
  width: calc(100% - 115px);
  float: right;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slide {
    border: 1px solid #efefef;
  }

  .slide-video {
    text-align: center;
    font-size: 0;

    .field-label {
      display: none;
    }
  }
}

.product-slider-nav {
  width: 93px;
  float: left;
  padding: 17px 0;

  .slide {
    padding: 5px 0;

    img {
      border: 1px solid #efefef;
    }
  }

  .slide-video {
    .field-item {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.55);
      }

      &:after {
        content: "\f04b";
        font-family: FontAwesome;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    left: 50%;
    width: 39px;
    height: 10px;
    background: url('../images/slider-arrow.png') 50% 100% no-repeat;
    background-size: cover;
    cursor: pointer;
    z-index: 5;
  }

  .slick-button-prev {
    top: 4px;
    transform: translateX(-50%);
  }

  .slick-button-next {
    bottom: 4px;
    transform: translateX(-50%) scaleY(-1);
  }
}

.commerce-add-to-cart {
  input[name="quantity"] {
    width: 73px;
    height: 35px;
    margin: 0 10px;
    border: 1px solid #f1f1f1;
    border-radius: 0;
    box-shadow: none;
    color: #000;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }
}


/*-----------------------
        Tabs
-----------------------*/

.product-tabs {
  margin: 85px 0 90px;
  font-size: 17px;
  font-weight: 300;

  .nav-tabs {
    margin: 0 0 45px;
    border: none;
    border-bottom: 1px solid #d3dad9;
  }

  li,
  li.active {
    a {
      font-size: 26px;
      font-weight: 300;
    }

    a,
    a:hover,
    a:focus {
      border: none;
      background: transparent;
    }
  }

  li.active {
    border-bottom: 1px solid #fff;

    a {
      color: #e5097f;
    }
  }

  li {
    a {
      padding: 0;
      color: #8fa4a2;
    }

    &.active {
      a,
      a:hover {
        color: #e5097f;
        text-decoration: none;
      }
    }
  }

  .tab-content {
    min-height: 50px;
  }
}

@media all and (min-width: 768px) {
  .product-tabs {
    li.active:first-child {
      padding-right: 35px;
    }

    li.active:nth-child(n + 2) {
      padding: 0 35px;
    }

    li:not(.active) + li:not(.active) {
      margin-left: 35px;
    }
  }
}

/*--------Params---------*/

.params {
  display: flex;
  margin-bottom: 5px;

  .bold {
    margin-right: 5px;
  }
}


/*--------Comments---------*/

.comment {

  .c-author-letter {
    display: inline-block;
    float: left;
    width: 100px;
    height: 100px;
    background: #d5dce4;
    color: #fff;
    font-size: 71px;
    font-weight: 500;
    line-height: 100px;
    text-align: center;
  }

  .comment-content {
    padding-left: 142px;
  }

  .field-name-comment-body {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  .c-top {
    display: flex;
    align-items: center;
  }

  .c-author-vote {
    margin-right: 20px;
  }

  .c-author,
  .c-author a {
    color: #898d8f;
    font-size: 18px;
    line-height: 26px;
  }

  .fivestar-static-item {
    .form-item {
      @extend .clearfix;
      margin: 0;
    }
  }

  .fivestar-widget-static {
    .star {
      width: 10px;
      height: 10px;

      span {
        background-size: contain !important;
      }
    }

    .star + .star {
      margin-left: 5px;
    }
  }
}

#comments {
  .comment {
    margin-top: 0;
  }

  .comment:not(:first-of-type) {
    border-top: 1px solid #f3f4f5;
    margin-top: 40px;
    padding-top: 45px;
  }

  .dropup {
    margin-top: 35px;
    padding-left: 142px;

    .dropdown-menu {
      bottom: 0;
      max-width: 560px;
      margin-bottom: 0;
      padding: 20px 25px 0;
      border-radius: 0;
      border: 1px solid #d4d4d4;
      box-shadow: none;

      @media all and (min-width: 1310px) {
        left: -53px;
      }
    }

    .cf-close {
      position: absolute;
      top: -1px;
      right: -1px;
      width: 29px;
      height: 29px;
      background: #d4d4d4 url("../images/cf-close.png") 50% 50% no-repeat;
      cursor: pointer;
    }

    .form-item-name,
    .form-type-item {
      display: none;
    }

    .field-type-fivestar {
      text-align: center;
    }

    .fivestar-user-stars {
      display: inline-block;
      padding: 7px 17px;
      border: 1px solid #c1ccd3;
    }

    textarea {
      border: none;
      border-bottom: 1px solid #d6d6d6;

      &::-webkit-input-placeholder {
        padding-top: 40px;
        color: #787c7f;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
      }

      &::-moz-placeholder {
        padding-top: 40px;
        color: #787c7f;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
      }

      &:-moz-placeholder {
        padding-top: 40px;
        color: #787c7f;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
      }

      &:-ms-input-placeholder {
        padding-top: 40px;
        color: #787c7f;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
      }
    }

    .form-actions {
      margin: 22px 0 0;
      text-align: center;
    }

    input[type='submit'] {
      @extend .woody-button;
      margin: 0;
      border: 1px solid #f8ab33;
      padding: 9px 20px 8px;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

#add-comment {
  background: #fff;
  border: 1px solid #e698c0;
  border-radius: 0;
  color: #e20075;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  outline: none;
  padding: 9px 20px 8px;
  cursor: pointer;
}


/*--------Comment Form---------*/

.comment-form {

  textarea {
    padding: 6px 8px;
    background: transparent;
    color: #4c3a2c;
    font-size: 20px;
    line-height: 20px;
    border: 1px solid #d6d6d6;
    border-radius: 0;
    box-shadow: none;
    resize: none;
    max-height: 85px;

    &:focus {
      border: 1px solid #d6d6d6;
      box-shadow: none;
    }
  }

  .grippie {
    display: none;
  }

  .fivestar-default {
    display: block;
    float: none;

    .star {
      width: 19px;
      height: 19px;
      background: none;

      a {
        background: url('../images/star-off.png') 0 50% no-repeat;
      }
    }

    .star.hover,
    .star.on {
      a {
        background: url('../images/star-on.png') 0 50% no-repeat;
      }
    }

    .star + .star {
      margin-left: 2px;
    }
  }

  input[type='submit'] {
    padding: 7px 12px 5px;
  }
}


.recom-products {
  margin-bottom: 68px;

  .title-wr {
    position: relative;
    margin-bottom: 70px;
    text-align: center;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: #d3dad9;
      z-index: 0;
    }
  }

  h2 {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0 16px;
    background: #fff;
    color: #1f1f1f;
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.field-name-field-similar-products {
  padding: 0 75px;

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    justify-content: center;
    height: auto;
    margin: 0 6px;
  }

  .slick-list {
    margin: 0 -6px;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    width: 18px;
    height: 183px;
    background: url('../images/slider-arrow-recom.png') 50% 50% no-repeat;
    cursor: pointer;
    z-index: 5;
  }

  .slick-button-prev {
    left: -75px;
    transform: translateY(-50%) scaleX(-1);
  }

  .slick-button-next {
    right: -75px;
    transform: translateY(-50%);
  }
}


/*-----------------------
        Add to cart message
-----------------------*/

.add-cart-message-wrapper {
  left: 25%;
  right: 25%;
  border: 2px solid #cdcc00;
  border-radius: 0;
  color: #7f6f5a;
  font-size: 16px;

  .option-button-wrapper {
    .option-button {
      width: 100%;
    }

    .checkout {
      @extend .woody-button;
      display: block;
      background: #fff;
      border: 1px solid #e5e5e5;

      &:hover {
        background: #fff;
        border-color: #cdcc00;
      }

      a {
        font-weight: 500;
      }
    }

    .continue {
      @extend .woody-button;
    }
  }

  .add-to-cart-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 36px;
    height: 35px;
    background: url('../images/close.png') 50% 50% no-repeat;
    opacity: 1;
    outline: none;
    z-index: 5;
  }
}


/*-----------------------
        Teaser
-----------------------*/

.toy-teaser {
  display: flex;
  flex-direction: column;
  position: relative;

  & > a {
    display: flex;
    flex-grow: 1;
  }

  .toy-teaser-iw {
    position: relative;
  }

  .toy-cover {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 325px;
    padding: 40px 20px 14px 20px;
  }

  .image {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 100%;

    img {
      margin: 0 auto;
    }
  }

  .labels {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .pr-label.new {
    color: #33c4bd;
  }

  .pr-label.top-sales {
    color: #ff3334;
  }

  .pr-label.discount {
    color: #e20075;
  }

  .pr-video {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;

    a {
      color: #33c4bd;
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }

  h3 {
    margin: 5px 0 0;
    color: #6b6b6b;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    background: #f9b23c;
    color: #fefefe;
    font-weight: 700;
  }

  .price-iw {
    display: flex;
    align-items: center;
  }

  .commerce-add-to-cart {
    & > div {
      display: flex;
      align-items: center;
    }
  }

  .form-item-quantity {
    display: flex;
    align-items: center;
    margin-right: 14px;
    margin-bottom: 0;

    label {
      display: none;
    }

    a {
      margin-bottom: 0;
      padding: 0;
      border-radius: 0;
      color: #fff;
      font-size: 18px;

      &:hover,
      &:focus {
        background: transparent;
        color: #fff;
      }
    }

    input[name="quantity"] {
      width: 29px;
      height: 30px;
      border: none;
      padding: 6px 2px;
      color: #f9b23c;
      font-weight: 600;
    }
  }

  .old-price {
    position: relative;
    margin-right: 14px;
    font-size: 18px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -5%;
      width: 110%;
      height: 1px;
      background: #fff;
    }

    .code {
      display: none;
    }
  }

  .current-price {
    font-size: 24px;

    .code {
      font-size: 16px;
    }
  }

  .old-price {
    font-size: 16px;
  }

  .current-price {
    line-height: 21px;

    .value.RUB {
      font-size: 21px;
    }
  }

  input[type="submit"] {
    border: none;
    background: transparent;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background: transparent;
    }
  }

  &.l-new {
    .price {
      background: #33c4bd;
    }

    .form-item-quantity {
      input[name="quantity"] {
        color: #33c4bd;
      }
    }
  }

  &.l-top-sales {
    .price {
      background: #ff3334;
    }

    .form-item-quantity {
      input[name="quantity"] {
        color: #ff3334;
      }
    }
  }

  &.l-discount {
    .price {
      background: #e20075;
    }

    .form-item-quantity {
      input[name="quantity"] {
        color: #e20075;
      }
    }
  }
}

.node-type-product {
  .toy-teaser {
    .form-item-quantity {
      display: none;
    }
  }
}


/*-----------------------
        Category
-----------------------*/

.view-category,
.view-search-page {
  margin-bottom: 60px;

  .view-content {
    @extend .row;

    &:before,
    &:after {
      //content: none;
    }

    @media all and (min-width: 768px) {
      //display: flex;
      //flex-wrap: wrap;
    }
  }

  .toy-teaser {
    @extend .col-sm-6;
    margin-bottom: 15px;

    @media all and (min-width: 768px) {
      //flex-basis: 50%;
    }
  }

  .pager-show-more {
    margin-top: 30px;
  }
}
