/*-----------------------
        Node
-----------------------*/

.node-type-blog {
  .pane-node-content,
  .pane-latest-blogs-panel-pane-1 {
    @media all and (min-width: 992px) {
      width: calc(100% * 10 /12);
    }
  }
}

.node-blog {
  margin-bottom: 50px;

  .submitted {
    color: #666;
    font-weight: 100;
    font-style: italic;
  }

  .content {
    margin-top: 15px;
    color: #696969;
    font-size: 16px;
    text-align: justify;
  }
}


/*-----------------------
        Blogs
-----------------------*/

.view-blogs {
  margin-bottom: 50px;
  @media all and (min-width: 992px) {
    //width: calc(100% * 10 /12);
  }

  .blog {
    padding: 35px 0;
    border-bottom: 1px solid #d7d7d7;
  }

  .blog-image {
    @media all and (min-width: 768px) {
      float: left;
      width: 25%;
      margin-right: 5%;
    }
  }

  .rs {
    @media all and (min-width: 768px) {
      padding-left: 30%;
    }
  }

  .read-more {
    margin-top: 10px;
    font-size: 15px;
    color: #e10687;
    text-decoration: underline;
  }
}

.pager-show-more,
.pager-load-more {
  margin-top: 40px !important;

  li > a {
    color: #ffffff;
    background: #cdcc00;
    padding: 10px 50px;
    font-weight: bold;

    &:hover,
    &:focus {
      background: #c3c201;
      color: #fff;
    }
  }
}

.pager-show-more .pager-show-more-next {
    margin: 0;
}

.blog {
  &:hover {
    .blog-title {
      text-decoration: underline;
    }

    .read-more {
      text-decoration: none;
    }
  }
}

.blog-title {
  margin-bottom: 10px;
  color: #222;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.blog-created {
  font-weight: 100;
  color: #666;
  font-style: italic;
}

.blog-descr {
  margin-bottom: 10px;
  color: #666;
}


/*-----------------------
        Latest blogs
-----------------------*/

.view-latest-blogs {
  margin-bottom: 50px;

  .blog-image {
    float: left;
    margin-right: 10px;
  }

  .rs {
    padding-left: 225px;
  }

  .read-more {
    margin-top: 10px;
    font-size: 15px;
    color: #e10687;
    text-decoration: underline;
  }

  .view-content {
    padding: 0 20px;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 30px;
    background: url('../images/slider-blog-arrow.png') 100% 50% no-repeat;
    background-size: cover;
    cursor: pointer;
    z-index: 5;
  }

  .slick-button-prev {
    left: 0;
    transform: translateY(-50%) scaleX(-1);
  }

  .slick-button-next {
    right: 0;
  }
}
